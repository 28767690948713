import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
const routes = [
  {
    path: '/factory',
    name: 'factory',
    meta: { title: '设备管理' },
    redirect: '/factory/imagetru3d/x_real_device_info',
    component: Main,
    children: [
      {
        path: '/factory/imagetru3d/x_real_device_info',
        name: 'x_real_device_info',
        meta: {
          title: 'X-real',
          parentPath: '/factory',
          permission: ['view']
        },
        component: () => import('@/views/imagetru3d/x_real/device_info.vue')
      },
      {
        path: '/factory/imagetru3d/screen32_device_info',
        name: 'screen32_device_info',
        meta: {
          title: '32寸光场全息屏',
          parentPath: '/factory',
          permission: ['view']
        },
        component: () => import('@/views/imagetru3d/device_info.vue')
      },
      {
        path: '/factory/imagetru3d/screen32_device_edit',
        name: 'screen32_device_edit',
        meta: {
          hidden: true,
          title: '32寸光场全息屏编辑',
          parentPath: '/factory',
          permission: ['view'],
          prevPath: '/factory/imagetru3d/screen32_device_info'
        },
        component: () => import('@/views/imagetru3d/device_edit.vue')
      },
      {
        path: '/factory/imagetru3d/screen32_device_add',
        name: 'screen32_device_add',
        meta: {
          hidden: true,
          title: '添加素材',
          parentPath: '/factory',
          permission: ['view'],
          prevPath: '/factory/imagetru3d/screen32_device_info'
        },
        component: () => import('@/views/content/material_manage.vue')
      },
      {
        path: '/factory/imagetru3d/screen65_device_info',
        name: 'screen65_device_info',
        meta: {
          title: '65寸光场全息屏',
          parentPath: '/factory',
          permission: ['view']
        },
        component: () => import('@/views/imagetru3d/device_info.vue')
      },
      {
        path: '/factory/imagetru3d/screen65_device_edit',
        name: 'screen65_device_edit',
        meta: {
          hidden: true,
          title: '65寸光场全息屏编辑',
          parentPath: '/factory',
          permission: ['view'],
          prevPath: '/factory/imagetru3d/screen65_device_info'
        },
        component: () => import('@/views/imagetru3d/device_edit.vue')
      },
      {
        path: '/factory/imagetru3d/screen65_device_add',
        name: 'screen65_device_add',
        meta: {
          hidden: true,
          title: '添加素材',
          parentPath: '/factory',
          permission: ['view'],
          prevPath: '/factory/imagetru3d/screen65_device_info'
        },
        component: () => import('@/views/content/material_manage.vue')
      },
      {
        path: '/factory/imagetru3d/device_info_edit',
        name: 'device_info_edit',
        meta: {
          hidden: true,
          title: '新增光场设备',
          parentPath: '/factory',
          prevPath: '/imagetru3d/device_info'
        },
        component: () => import('@/views/imagetru3d/x_real/device_info_edit.vue')
      }
    ]
  }
]

export default routes
