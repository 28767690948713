import Main from '@/layout/main.vue'

const routes = [
  {
    path: '/content',
    name: 'content',
    meta: { title: '内容管理' },
    redirect: '/content/x_real',
    component: Main,
    children: [
      {
        path: '/content/x_real',
        name: 'content_x_real',
        meta: {
          title: 'X-real',
          parentPath: '/content',
          permission: ['view']
        },
        redirect: '/content/x_real/workbench',
        component: () => import('@/views/content/x_real/index.vue'),
        children: [
          {
            path: '/content/x_real/workbench',
            name: 'x_real_workbench',
            meta: {
              title: '工作台',
              parentPath: '/content',
              icon: 'icon_goods',
              permission: ['view'],
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/workbench.vue')
          },
          {
            path: '/content/x_realworkbench/allOpenTopic',
            name: 'x_real_workbench_open_topic',
            meta: {
              title: '全部公开主题',
              parentPath: '/content',
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/topic_manage.vue')
          },
          {
            path: '/content/x_real/topic',
            name: 'x_real_topic_manage',
            meta: {
              title: '主题管理',
              icon: 'icon_caiwu_tixian',
              parentPath: '/content',
              permission: ['view'],
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/topic_manage.vue')
          },
          {
            path: '/content/x_real/new_topic/',
            name: 'x_real_new_topic',
            meta: {
              title: '新增主题',
              parentPath: '/content',
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/new_topic.vue')
          },
          {
            path: '/content/x_real/edit_topic/:id',
            name: 'x_real_edit_topic',
            meta: {
              title: '编辑主题',
              parentPath: '/content',
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/new_topic.vue')
          },
          {
            path: '/content/x_real/material',
            name: 'x_real_material_manage',
            meta: {
              title: '素材管理',
              icon: 'icon_dianpu_sucai',
              parentPath: '/content',
              permission: ['view'],
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/material_manage.vue')
          },
          {
            path: '/content/x_real/new_material',
            name: 'x_real_new_material',
            meta: {
              title: '新增素材',
              parentPath: '/content',
              hidden: true,
              prevPath: '/content/x_real'
            },
            component: () => import('@/views/content/x_real/new_material.vue')
          }
        ]
      },
      {
        path: '/content/screen32',
        name: 'content_screen32',
        meta: {
          title: '32寸全息光场屏',
          parentPath: '/content',
          permission: ['view']
        },
        component: () => import('@/views/content/material_manage.vue')
      },
      {
        path: '/content/screen32/new_material',
        name: 'content_screen32_new_material',
        meta: {
          title: '新增素材',
          parentPath: '/content',
          hidden: true,
          prevPath: '/content/screen32'
        },
        component: () => import('@/views/content/new_material.vue')
      },
      {
        path: '/content/screen65',
        name: 'content_screen65',
        meta: {
          title: '65寸全息光场屏',
          parentPath: '/content',
          permission: ['view']
        },
        component: () => import('@/views/content/material_manage.vue')
      },
      {
        path: '/content/screen65/new_material',
        name: 'content_screen65_new_material',
        meta: {
          title: '新增素材',
          parentPath: '/content',
          hidden: true,
          prevPath: '/content/screen65'
        },
        component: () => import('@/views/content/new_material.vue')
      }
    ]
  }]
export default routes
