
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
// 注解
// 装饰器中的“东西”被框架传给了父类 Vue 中
@Component
export default class App extends Vue {
  @Action('getPermission') getPermission!: () => void
  @Action('getConfig') getConfig!: () => void
  async created () {
    this.getPermission()
    this.getConfig()
  }
}
