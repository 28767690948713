import request from '@/plugins/axios' // base_基础服务
import _cloud_request from '@/plugins/cloud_axios'// cloud服务
// 登录
export const apiLogin = (data: any) => _cloud_request.post('/do_login', data)

// 退出登录
export const apiLogout = () => _cloud_request.get('/user/login_out')

// 配置
export const apiconfig = () => _cloud_request.get('/config/getConfig')

// 退出登录
export const apiDownLoad = () => request.get('', { params: { d: 1 } })

// 页面跳转列表
export const apiLinkList = () => request.get('/theme.DecorateThemePage/getshoppage')

// 权限列表
export const apiAuth = () => request.get('/config/getAuth')
